import React from 'react'
import { FaTwitter } from "react-icons/fa";
import { FaArrowAltCircleUp } from "react-icons/fa";
import { Link, NavLink, useLocation } from "react-router-dom";
import {  useNavigate } from 'react-router-dom';
import './Navbar.css'
import logo from './assets/logo.png'

import { FaXTwitter } from "react-icons/fa6";
import { FiFacebook } from "react-icons/fi";
import { IoLogoInstagram } from "react-icons/io5";

const Footer = () => {
  return (
    <div className='container px-0' style={{backgroundColor:'#EFEFEF', minWidth:'100%'}}> 
     {/* <a className="navbar-brand  d-flex justify-content-start py-1" style={{height:'30px',width:'30px'}} href="#"><FaArrowAltCircleUp style={{height:'30px',width:'30px'}} className='fs-3'/></a>
     <div className=' d-flex flex-wrap justify-content-center py-3'>
            <a className="nav-link active mx-3 hoverf p-2" aria-current="page" href="#Features">Features</a>
            <a className="nav-link active mx-3 hoverf p-2" aria-current="page" href="#Testimonials">Testimonies</a>
            <a className="nav-link active mx-3 hoverf p-2" aria-current="page" href="#Pricing">Pricing</a>
            <NavLink className="nav-link active mx-3 hoverf p-2" to="/privacy">Privacy Policy</NavLink>
            <NavLink className="nav-link active mx-3 hoverf p-2" to="/tc">Terms of Service</NavLink>



      </div>
      <div className=' d-flex justify-content-between py-2'>
        <p style={{color:'black'}}> Copyright © 2024 Podcast Summarize. All rights reserved.</p>
        <a style={{color:'black'}} href='https://twitter.com/PodSummarize' ><FaTwitter /></a>
      </div> */}
      <div className='d-flex justify-content-center'>
<img src={logo} style={{width:'100px'}} className='mt-4'/>
      </div>
      <div className='custom-border ' >
      <div className='d-flex justify-content-center fs-5 mb-0 mt-3' style={{color:'#5B058F'}}>
<p className='mb-0'>Concise and Relevant Podcast Summaries sent to your inbox</p>
      </div>
      <div className='d-flex justify-content-center fs-5  ' style={{color:'#5B058F'}}>
<p >weekly to elevate your Podcast knowledge.</p>
      </div>
      </div>
      <div className='d-flex justify-content-center pt-5 pb-4 fs-2' style={{color:'#FF8730'}}>
      <FaXTwitter className='mx-3' />
      <FiFacebook className='mx-3' />
      <IoLogoInstagram className='mx-3' />
      </div>
      <div className='d-flex justify-content-between px-3 py-4 fs-6 flex-wrap' style={{backgroundColor:'#C463FF',color:'white'}}>
      <div className='my-2'>Copyright © 2025 Podcast Summarize. All rights reserved.</div>
      <div className='d-flex justify-content-center my-2'>
  <div className='me-3 pe-3 ' style={{ borderRight: '1px solid white' }}>
    <a href='/privacy' className='text-decoration-none text-white'>
      Privacy Policy
    </a>
  </div>
  <div>
    <a href='/tc' className='text-decoration-none text-white'>
      Terms and Conditions
    </a>
  </div>
</div>
      </div>
  </div>
  )
}

export default Footer